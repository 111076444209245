

<template>
  <div class="Attend_3">
    <div class="tab_Top">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect">
        <el-menu-item index="1">考勤组设置</el-menu-item>
        <el-menu-item index="2">班次设置</el-menu-item>
        <el-menu-item index="3">假期设置</el-menu-item>
        <el-menu-item index="4">考勤数据</el-menu-item>
      </el-menu>
      <div class="Men_1">
        说明：设置考勤部门与人员、考勤班次、考勤方式（Wi-Fi）等相关规则，点击下方添加考勤进入按步骤设置即可。
      </div>
    </div>
    <div class="Tables">
       <div class="Tab_T" @click="Xinjian()" v-if="TextMoMo('attendance:class:add')"><img class="Img_ti" src="../../assets/img/+++.png" alt="">添加假期</div>
        <div class="el_seso"><i class="el-icon-search" @click="inpu_sech"></i></div>
        <div class="dsds">
          <el-input v-model="input_seach" placeholder="请输入内容" @keyup.enter.native="inpu_sech()"></el-input>
        </div>
        <div v-if="TextMoMo('workbench:guide:book:add')">
          <el-table
            :data="tableData"
            class="table"
            ref="multipleTable"
            header-cell-class-name="table-header"
            @selection-change="handhange">
            <el-table-column
              type="selection"
              width="55"
              align="center"></el-table-column>
            <el-table-column
              prop="id"
              label="ID"
              width="65"
              align="center"></el-table-column>
            <el-table-column align="center" prop="intro" label="介绍" width="300"></el-table-column>
            <el-table-column align="center" prop="type_text" label="类型" width="180"></el-table-column>
            <el-table-column align="center" prop="starttime_text" label="开始时间" width="310"></el-table-column>
            <el-table-column align="center" prop="endtime_text" label="结束时间" width="310"></el-table-column>
            <el-table-column label="操作" align="center" >
              <template #default="scope" >
                  <div class="el_butto" >
                    <el-button type="text" @click="Handlbchak(scope.row)" v-if="TextMoMo('attendance:class:read')">查看</el-button>
                    <el-button type="text" @click="Handlbchak(scope.row)" v-if="!TextMoMo('attendance:class:read')">查看</el-button>
                  </div>
                  <div class="el_butto" >
                    <el-button type="text" @click="Handlbchak(scope.row)" v-if="TextMoMo('attendance:class:edit')">编辑</el-button>
                  </div>
                <div class="el_butto rere" >
                    <el-button v-if="TextMoMo('attendance:class:del')"
                    type="text"
                    class="red"
                    @click="HanDel(scope.row)"
                    >删除</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="block">
            <el-pagination
              background
              @current-change="handhange1"
              :current-page="currentPage1"
              :page-size="page.pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total1">
            </el-pagination>
          </div>
        </div>

    </div>

    <el-dialog title="创建/编辑假期" v-model="editVisible" width="28%">
      <el-form ref="form" label-width="100px">
        <el-form-item label="介绍">
          <el-input v-model="List_G.intro" placeholder="请输入介绍内容"></el-input>
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="List_G.type" placeholder="请选择类型" style=" width: 100%;">
              <el-option v-for="item in Opidss" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开始时间">
          <el-date-picker v-model="List_G.starttime_text" type="date" placeholder="选择时间" :clearable="false" style=" width: 100%;"></el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间">
          <el-date-picker v-model="List_G.endtime_text" type="date" placeholder="选择时间" :clearable="false" style=" width: 100%;"></el-date-picker>
        </el-form-item>        
      </el-form>
      <template #footer>
        <span class="dialog-footer" style="margin-bottom:20px">
          <el-button @click="editVisible = false">取 消</el-button>
          <el-button type="primary" v-if="XX == 1" @click="saveEdit2">确 定</el-button>
          <el-button type="primary" v-if="XX == 2"  @click="BTN_tj2">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog title="查看假期" v-model="editVisible11" width="28%">
      <el-form ref="form" label-width="100px">
        <el-form-item label="介绍">
          <el-input v-model="List_G.intro" placeholder="请输入介绍内容" disabled></el-input>
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="List_G.type" placeholder="请选择类型" disabled style=" width: 100%;">
              <el-option v-for="item in Opidss" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开始时间" >
          <el-date-picker v-model="List_G.starttime_text" type="date" placeholder="选择时间" :clearable="false" disabled style="width:100%"></el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间">
          <el-date-picker v-model="List_G.endtime_text" type="date" placeholder="选择时间" :clearable="false" disabled style=" width: 100%;"></el-date-picker>
        </el-form-item>        
      </el-form>
      <template #footer>
        <span class="dialog-footer" style="margin-bottom:20px">
          <el-button @click="editVisible11 = false">取 消</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import  TextMoth  from "../../components/utils.vue"; 
export default {
  name: "basetable",
  data() {
    return {
      editVisible11:false,
      Opidss:[{name:'上班',id:'work'},{name:'休息',id:'rest'}],
      input_seach:'',
      activeIndex: "3",  
      Fror: {
        ssid:'',   
        bssid:'', 
      },
      radio:'1',
      input: "",
      query: {
          address: "",
          name: "",
          pageIndex: 1,
          pageSize: 10
      },
      tableData: [],
      multipleSelection: [],
      delList: [],
      editVisible: false,
      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1,
      Handlbchak_id:0,
      WifiList:[],  
      List_G: {},
      LocationList:[],
      StaFFList:[],      //全部员工列表
      DMent:[],
      SectionsList:[],
      XX:0 ,                      //1新建  2编辑
    };
  },
  mounted() {
    var that = this
      axios({
        method: 'post',
        url: '/admin/Attendance/calendar/index', 
        data: {
          page: 1,
          offset:0,
          limit:10,
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total1 = res.data.data.total
      })   
    axios.get('/admin/ajax/staffList',{                   // 全部员工列表
        }).then(function (res) {
          if (res.data.code == 0) {
            alert('请求失败');  
          } else{ 
            console.log(res.data.data)
              that.StaFFList = res.data.data
          }
    })   
    axios.get('/admin/company/department/index',{
      }).then(function (res) {
        if (res.code == 0) {
          alert('请求失败');  
        } else{ 
          that.DMent = res.data.data.rows
        }
    })
  },
  methods: {
    TextMoMo(e) {
      var arr = TextMoth.getData(e)
      return arr  
    },
    Radio(e) {
      var that = this
      that.SectionsList = []
      var arr = that.SectionsList
      var acc = {start:'',end:''}
      if (e == 1) {
        arr.push(acc)
      } else if (e == 2) {
        arr.push(acc)
        arr.push(acc)
      } else if (e == 3) {
        arr.push(acc)
        arr.push(acc)
        arr.push(acc)
      }
    },
    handl_Del(e,index, data) {
      var that = this
      if (e == 1) {
      } else if (e == 2) {
        var LnList = that.WifiList
        LnList.splice(index,1)
      }
    },
    saveEdit2(){                       //确认添加
      var that = this
          that.List_G.starttime = that.List_G.starttime_text
          that.List_G.endtime = that.List_G.endtime_text
        axios({
          method: 'post',
          url: '/admin/Attendance/calendar/add',
          data: {
            row: that.List_G
          },
        }).then(function (res) {
            if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
            } else{ 
                that.$message.success(res.data.msg);
                axios({
                  method: 'post',
                  url: '/admin/Attendance/calendar/index', 
                  data: {
                    page: 1,
                    offset:0,
                    limit:10,
                  },
                }).then(function (res) {
                  that.tableData = res.data.data.rows
                  that.total1 = res.data.data.total
                })   
              }
        }) 
      that.editVisible = false
    },
    Tjwei(e) {                         //添加
      var that = this
      if (e == 1) {
        that.editVisible = true
      } else if (e ==2) {
        that.editVisible2 = true
      }
    },
    Xinjian() {
      var that = this
      that.XX = 1
      that.editVisible = true
      that.List_G = {}
    },

    BTN_tj2() {                        //编辑提交
      var that = this
      // that.List_G.sections = that.SectionsList
          that.List_G.starttime = that.List_G.starttime_text
          that.List_G.endtime = that.List_G.endtime_text
          axios({
          method: 'put',
          url: '/admin/Attendance/calendar/edit',
          data: {
            id: that.Handlbchak_id,
            row: that.List_G
          },
        }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
               that.$message.success(res.data.msg);
                that.editVisible = false
                axios({
                  method: 'post',
                  url: '/admin/Attendance/calendar/index', 
                  data: {
                    page: 1,
                    offset:0,
                    limit:10,
                  },
                }).then(function (res) {
                  that.tableData = res.data.data.rows
                  that.total1 = res.data.data.total
                })   

            }

        }) 
    },
    handhange1(val) {
      var that = this
        axios({
          method: 'post',
          url: '/admin/Attendance/calendar/index', 
          data: {
            page: val,
            offset:0,
            limit:10,
            filter: {
              keyword: that.input_seach
            }
          },
        }).then(function (res) {
          that.tableData = res.data.data.rows
          that.total1 = res.data.data.total
        })   
    },
    Close() {
      this.show1 = false
      this.show2 = false
    },
    Handlbchak(e) {                           //编辑
      var that = this
      that.XX = 2
      that.Handlbchak_id = e.id
      that.editVisible = true
      that.List_G.intro = e.intro
      that.List_G.type = e.type
      that.List_G.endtime_text = e.endtime_text
      that.List_G.starttime_text = e.starttime_text
    },
    handleSelect(e) {
      if (e == 1) {
        this.$router.push('/Manage/Attend');
      }else if (e == 2) {
        this.$router.push('/Manage/Attend_2');
      } else if (e == 3) {
        this.$router.push('/Manage/Attend_3');
      }
    },

    // 触发搜索按钮
    inpu_sech() {
        var that = this
        axios({
          method: 'post',
          url: '/admin/Attendance/calendar/index', 
          data: {
            page: 1,
            offset:0,
            limit:10,
            filter: {
              keyword: that.input_seach
            }
          },
        }).then(function (res) {
          that.tableData = res.data.data.rows
          that.total1 = res.data.data.total
        })          
    },
    // 删除操作
    HanDel(e) {
        // 二次确认删除
        var that = this
        var arr = []
        arr.push(e.id)
        that.$confirm("确定要删除吗？", "提示", {
            type: "warning"
        }).then(() => {
            axios.delete('/admin/Attendance/calendar/del',{ 
              params:{
                    ids: arr
              }
            }).then(function (res) {
            if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
            } else{ 
                that.$message.success(res.data.msg);
                axios({
                  method: 'post',
                  url: '/admin/Attendance/calendar/index', 
                  data: {
                    page: 1,
                    offset:0,
                    limit:10,
                  },
                }).then(function (res) {
                  that.tableData = res.data.data.rows
                  that.total1 = res.data.data.total
                })   
              }
          })   
        })
    },
    formatDate(value) {    // 时间戳
        let date = new Date(value);
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? "0" + MM : MM;
        let d = date.getDate();
        d = d < 10 ? "0" + d : d;

        return y + "-" + MM + "-" + d
    },

  },
};
</script>


<style scoped>
.Attend_3  .el-date-editor.el-input{
  width: 100%;
}
.Attend_3 .Fiooi .el-input, .Attend_3 .Fiooi .el-input__inner {
  width: 704px;
}
.Attend_3 .amap-wrapper {
  width: 500px;
  height: 500px;
}
.Attend_3 #app {
  width: 100%;
  height: 300px;
  background: #d9d9d9;
} 
</style>
